import React, { useState } from "react";
import Helmet from "react-helmet";
import gql from "graphql-tag";

import {
  ButtonTypes,
  Button,
  HeadbandCarousel,
  Card
} from "benlux-ecommerce-ui";
import { Query } from "@deity/falcon-ecommerce-uikit";

import { AddToCartMutation } from "../mutation/CartMutation";
import { OpenSidebarMutation } from "../pages/shop/components/Sidebar/SidebarMutations";

import benluxXRubanRose from "./benlux-x-ruban-rose.png";
import octobreRoseBackground from "./octobre-rose-background.jpg";
import image1 from "./octobre-rose/images/image1.jpg";
import image4 from "./octobre-rose/images/image2.jpg";
import image2 from "./octobre-rose/images/image3.jpg";
import image3 from "./octobre-rose/images/image4.jpg";
import image5 from "./octobre-rose/images/image5.jpg";

import "./octobre-rose/octobre-rose.css";

const HOMEPAGE_PRODUCTS_QUERY = gql`
  query HomepageProducts($categoryId: String!, $limit: Int) {
    category(id: $categoryId) {
      name
      description
      urlPath
      products(pagination: { perPage: $limit, page: 1 }) {
        items {
          id
          name
          brandName
          description
          shortDescription
          promotionalBadge
          manufacturer
          price {
            regular
            special
          }
          configurableOptions {
            values {
              price {
                regular
                special
              }
            }
          }
          attributes {
            attributeCode
            value
          }
          gallery {
            full
            thumbnail
          }
          image
          thumbnail
          urlPath
        }
      }
    }
  }
`;

function DonationForm({ addDonation, loading }) {
  const fixedAmounts = [1, 2, 5];
  const [amount, setAmount] = useState(fixedAmounts[0]);

  return (
    <div style={{ margin: "12px 0" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {fixedAmounts.map((value, index) => (
          <div
            key={index}
            onClick={() => setAmount(value)}
            style={{
              cursor: "pointer",
              borderRadius: "5px",
              width: "40px",
              textAlign: "center",
              backgroundColor: "#FFF",
              marginRight: "10px",
              lineHeight: "48px",
              height: "48px",
              minWidth: "48px",
              color: "#555",
              border: amount === value ? "1px solid #e52678" : "1px solid #CCC"
            }}
          >
            {value} &euro;
          </div>
        ))}
        <input
          type="number"
          min="1"
          onChange={event => setAmount(event.target.valueAsNumber)}
          placeholder="Montant libre"
          style={{
            width: "110px",
            height: "48px",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #CCC"
          }}
        />
      </div>
      <Button
        as={"button"}
        onClick={() =>
          addDonation({
            variables: {
              input: {
                sku: "don-ruban-rose",
                qty: 1,
                donationOptions: {
                  amount: amount
                }
              }
            }
          })
        }
        type={ButtonTypes.Primary}
        text={`Faire un don de ${amount} €`}
        loading={loading}
        style={{ marginTop: "12px", background: "#e52678" }}
      />
    </div>
  );
}

export const DonationWidget = ({ onCompleted, onError }) => (
  <div
    style={{
      backgroundColor: "#fecad7",
      borderRadius: "10px",
      padding: "40px",
      margin: "0 0 24px 0",
      textAlign: "left"
    }}
  >
    <p style={{ fontSize: "20px", margin: 0, fontWeight: "500" }}>
      Soutenez l'association Ruban Rose !
    </p>
    <p style={{ fontSize: "14px" }}>
      Choisissez le montant de votre don pour l'ajouter à votre panier.
      <br />
      Benlux le reversa à l'association Ruban Rose (
      <a
        style={{ color: "#000" }}
        href="https://www.cancerdusein.org"
        target="__blank"
      >
        cancerdusein.org
      </a>
      ) pour soutenir la recherche contre le Cancer du Sein.
    </p>
    <AddToCartMutation
      onCompleted={data => onCompleted && onCompleted(data)}
      onError={data => onError && onError(data)}
    >
      {(addToCart, result) => (
        <DonationForm addDonation={addToCart} loading={result.loading} />
      )}
    </AddToCartMutation>
    {/* <a target="__blank" href="./octobre-rose">En savoir plus sur Benlux x Ruban Rose</a> */}
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <img
        src={benluxXRubanRose}
        style={{ width: "200px", margin: "0 -10px -20px 0" }}
      />
    </div>
  </div>
);

const OctobreRose = () => (
  <>
    <Helmet>
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:domain" content="benlux.fr" />
      <meta
        property="twitter:url"
        content={"https://www.benlux.fr/octobre-rose"}
      />
      <meta
        name="twitter:title"
        content={
          "BENLUX x Ruban Rose - Ensemble contre le cancer du sein ‣ BENLUX"
        }
      />
      <title>
        BENLUX x Ruban Rose - Ensemble contre le cancer du sein ‣ BENLUX
      </title>
      <meta name="page-loaded" content="true" />
    </Helmet>
    <article>
      <header
        style={{
          background: "url(" + octobreRoseBackground + ")",
          backgroundSize: "cover",
          textAlign: "center",
          padding: "28px 48px 24px"
        }}
      >
        <img
          src={benluxXRubanRose}
          alt="Benlux x Ruban Rose"
          style={{ width: "350px", maxWidth: "100%" }}
        />
        <h1 style={{ fontSize: "18px", fontWeight: "500" }}>
          En Octobre, tous ensemble pour soutenir la recherche contre le Cancer
          du Sein !
        </h1>
      </header>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>Benlux s’engage à prévenir et à soutenir !</h2>
        <p>
          A l’occasion d’Octobre Rose, campagne nationale de sensibilisation au
          cancer du sein, Benlux tenait à s’engager pour prévenir et encourager
          au dépistage. Pendant tout le mois, aidons à aider la recherche en
          faisant un don sur{" "}
          <a
            style={{ color: "#000" }}
            href="https://www.cancerdusein.org"
            target="__blank"
          >
            cancerdusein.org
          </a>{" "}
          et également dans votre panier benlux.fr pour toute commande, sans
          minimum d’achat.
        </p>
        <OpenSidebarMutation>
          {openSidebar => (
            <DonationWidget
              onCompleted={data =>
                openSidebar({
                  variables: {
                    contentType: "cart",
                    contextKey: "addSuccess",
                    contextItemId: data.addToCart.itemId
                  }
                })
              }
              onError={({ graphQLErrors }) =>
                openSidebar({
                  variables: {
                    contentType: "cart",
                    error: graphQLErrors[0].extensions.code,
                    contextKey: "addError"
                  }
                })
              }
            />
          )}
        </OpenSidebarMutation>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "72px auto 48px",
          padding: "0 16px"
        }}
      >
        <h2>Découvrez les produits de la collection Ruban Rose</h2>
        <p>
          Pour tout achat d’un produit de la collection une partie est reversée
          à l’association Ruban Rose.
        </p>
        <Query
          query={HOMEPAGE_PRODUCTS_QUERY}
          variables={{ categoryId: "1148", limit: 2 }}
        >
          {({
            category: {
              products: { items }
            }
          }) => {
            return (
              <div className="orProductsCollection">
                {items.map((item, index) => (
                  <Card
                    key={index}
                    item={item}
                    urlImg={item.thumbnail}
                    brand={item.brandName}
                    description={item.shortDescription}
                    name={item.name}
                    isNew={item.new}
                    isConfigurable={
                      item.configurableOptions &&
                      item.configurableOptions.length > 0 &&
                      item.configurableOptions[0].values.length > 1
                    }
                    cardLink={item.urlPath}
                    imgLazyLoading={false}
                  />
                ))}
              </div>
            );
          }}
        </Query>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>Ruban Rose soutient la recherche</h2>
        <p>
          A l’occasion d’Octobre Rose, campagne nationale de sensibilisation au
          cancer du sein, Benlux tenait à s’engager pour prévenir et encourager
          au dépistage. Pendant tout le mois, aidons à aider la recherche en
          faisant un don sur cancerdusein.org et également dans votre panier
          benlux.fr pour toute commande, sans minimum d’achat.
        </p>
        <img
          className="orImg"
          src={image1}
          style={{ width: "400px", margin: "12px 0 24px" }}
        />
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>Les cancers du sein</h2>
        <p>
          Ce sont des tumeurs qui se situent au niveau du sein et qui sont
          formées de cellules malignes, lesquelles se multiplient jusqu’à
          détruire le sein. Il arrive que certaines cellules cancéreuses se
          détachent de cette tumeur mammaire pour se disséminer dans l’organisme
          et former de nouvelles tumeurs ailleurs, appelées métastases. Il
          existe de nombreuses formes de cancers du sein, et leurs évolutions
          sont différentes. Les stratégies de traitement sont, autant que
          possible, adaptées à chaque forme de cancers du sein.
        </p>
        <img
          className="orImg"
          src={image2}
          style={{ width: "240px", margin: "12px 0 24px" }}
        />
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>Les signes possibles de cancer du sein</h2>
        <p>
          Plus un cancer du sein est dépisté tôt, plus il a de chances d’être
          bien soigné. C’est important de bien connaître sa poitrine en
          l’observant régulièrement. Voici des exemples de symptômes à
          surveiller :
        </p>
        <img
          className="orImg"
          src={image3}
          style={{ width: "480px", margin: "12px 0 24px" }}
        />
        <p>
          Le but n’est pas de vous alarmer mais de vous aider à repérer
          rapidement des changements. En cas de doute… CONSULTEZ UN OU UNE
          PROFESSIONNEL.LE DE SANTE.
        </p>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>L’autopalpation, un geste simple</h2>
        <p>
          L’autopalpation est un geste de plus dans la prévention du cancer du
          sein mais ne se substitue pas à votre visite régulière chez votre
          gynécologue, médecin ou sage-femme.
        </p>
        <img
          className="orImg"
          src={image4}
          style={{ width: "480px", margin: "12px 0 24px" }}
        />
        <p>
          Si vous constatez la moindre anomalie, contactez votre médecin sans
          attendre.
        </p>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          textAlign: "center",
          margin: "48px auto",
          padding: "0 16px"
        }}
      >
        <h2>Les chiffres d’espoir</h2>
        <p>
          Heureusement, sur 5 grosseurs examinées, 4 sont bénignes. Il est très
          important d’être bien suivie par un médecin généraliste ou un
          gynécologue et de faire des mammographies tous les 2 ans ou tous les
          ans selon l’évaluation du médecin ou du gynécologue.
        </p>
        <p>
          Grâce au dépistage précoce, la moitié des cancers du sein sont décelés
          alors qu’ils mesurent moins de 2 cm ! Ce niveau de détection permet
          d’atteindre des taux de guérison très élevés tout en réduisant
          considérablement l’agressivité des traitements appliqués : plus un
          cancer est détecté tôt, plus la tumeur sera petite et moins les
          traitements seront lourds. C’est pourquoi il est essentiel que les
          femmes puissent connaître et observer les conseils de dépistage
          précoce.
        </p>
        <img
          className="orImg"
          src={image5}
          style={{ width: "240px", margin: "12px 0 24px" }}
        />
      </div>
    </article>
  </>
);

export default OctobreRose;
